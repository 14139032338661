import { createRouter, createWebHistory } from "vue-router";


//guards
import { checkUserSessionGuard } from "./guards/checkUserSession";
import { RolesEnum } from "@/helpers/enums/RolesEnum";

const routes = [

  {
    path: "/register",
    name: "register",
    component: ()=> import("../views/General/RegisterView.vue"),
    meta: {
      hideNavbar: true,
    },
    
  },
  
  {
    path: "/login",
    name: "login",
    component: ()=> import("../views/General/LoginView.vue"),
    meta: {
      hideNavbar: true,
    }, 
  },

  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ()=> import("../views/General/ResetPasswordView.vue"),
    meta: {
      hideNavbar: true,
    }, 
  },

  {
    path: "/reset-password-form",
    name: "ResetPasswordForm",
    component: ()=> import("../views/General/ResetPasswordFormView.vue"), 
    meta: {
      hideNavbar: true,
    }, 
  },
  { path : '/consulta-orden', name: 'ConsultaDetalleOrden', component : ()=> import("../views/Ordenes/OrdenDetalleView.vue")},
  { path : '/consulta-equipo', name: 'ConsultaDetalleEquipo', component : ()=> import("../views/Equipos/EquipoDetalleView.vue")},
  { path : '/encuesta', name: 'Encuesta', component : ()=> import("../views/Encuesta/EncuestaPage.vue")},

  {
    path: "",
    name: 'Main',
    component: ()=> import("../views/MainLayout.vue"), 
    beforeEnter: [checkUserSessionGuard],
    children: [
      { path : '',   name: 'Home', redirect: 'Dashboard'},
      {path: 'Dashboard', name: 'Dashboard', component : ()=> import("../views/General/DeshboardView.vue"),   meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] },
      },
        {path: 'MapaDeCalor', name: 'MapaDeCalor', component : ()=> import("../views/Ordenes/MapaDeCalorView.vue"),   meta: { roles: [RolesEnum.ADMINISTRADOR] },
      },

      {
        path: "Reparaciones",
        name: "ListaReparaciones",
        component: ()=> import("../views/Reparaciones/ListaReparacionesView.vue"),
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }
      },
      {
        path: "NuevaReparaciones",
        name: "NuevaReparaciones",
        component: ()=> import("../views/Reparaciones/NuevaReparacionesView.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      {
        path: "Informes",
        name: "Informes",
        component: ()=> import("../views/Informes/informesView.vue"),
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
    
      {
        path: "Equipos",
        name: "Equipos",
        component: ()=> import("../views/Equipos/ListadoEquiposView.vue"),
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
    
      {
        path: "Equipos/crear",
        name: "CrearEquipo",
        component: ()=> import("../views/Equipos/InformacionEquipo.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      
      {
        path: "Equipos/:ns(.*)/editar",   
        name: "EditarEquipo",
        component: ()=> import("../views/Equipos/InformacionEquipo.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }
      },

      {
        path: "Habilitaciones",
        name: "Habilitaciones",
        component: ()=> import("../views/Habilitaciones/ListadoHabilitacionesView.vue"),
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
    
      {
        path: "Habilitaciones/crear",
        name: "CrearHabilitacion",
        component: ()=> import("../views/Habilitaciones/DetalleHabilitacion.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      
      {
        path: "Habilitaciones/:id/editar",   
        name: "EditarHabilitacion",
        component: ()=> import("../views/Habilitaciones/DetalleHabilitacion.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "Sucursales/crear",
        name: "CrearSucursal",
        component: ()=> import("../views/Configuracion/Sucursales/DetalleSucursalesView.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR] }

      },
      
      {
        path: "Sucursales/:id/editar",   
        name: "EditarSucursal",
        component: ()=> import("../views/Configuracion/Sucursales/DetalleSucursalesView.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR] }

      },

      {
        path: "OrdenesEliminadas",
        name: "OrdenesEliminadas",
        component: ()=> import("../views/Ordenes/OrdenesEliminadas.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR] }

      },

      {
        path: "TiposEquipos",
        name: "TiposEquipos",
        component: ()=> import("../views/Equipos/TiposEquiposView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }
  
      },
      
      {
        path: "Marcas",
        name: "Marcas",
        component: ()=> import("../views/Equipos/MarcasView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
    
      {
        path: "Modelos",
        name: "Modelos",
        component: ()=> import("../views/Equipos/ModelosView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "Checklists",
        name: "Checklists",
        component: ()=> import("../views/Equipos/ChecklistView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }
  
      },

      {
        path: "Checklist/crear",
        name: "CrearChecklist",
        component: ()=> import("../views/Equipos/ChecklistDetalle.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }
  
      },

      {
        path: "Checklist/:id(\\d+)?/editar",
        name: "EditarChecklist",
        component: ()=> import("../views/Equipos/ChecklistDetalle.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }
  
      },

      {
        path: "PlanesMantenimiento",
        name: "PlanesMantenimiento",
        component: ()=> import("../views/Equipos/PlanesMantenimientoView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }
  
      },
      

      {
        path: "PlanMantenimiento/crear",
        name: "CrearPlanMantenimiento",
        component: ()=> import("../views/Equipos/PlanMantenimientoDetalle.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }
  
      },

      {
        path: "PlanMantenimiento/:id(\\d+)?/editar",
        name: "EditarPlanMantenimiento",
        component: ()=> import("../views/Equipos/PlanMantenimientoDetalle.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }
  
      },

      {
        path: "rma",
        name: "RMA",
        component: ()=> import("../views/Equipos/RMAView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }
  
      },


      {
        path: "Clientes",
        name: "Clientes",
        component: ()=> import("../views/Clientes/ListadoClientesView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      {
        path: "Clientes/Crear",
        name: "CrearCliente",
        component: ()=> import("../views/Clientes/InformacionCliente.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      {
        path: "Clientes/:id(\\d+)?/editar",
        name: "EditarCliente",
        component: ()=> import("../views/Clientes/InformacionCliente.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
    
      {
        path: "Servicios",
        name: "Servicios",
        component: ()=> import("../views/Servicios/ServiciosView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "Reclamos",
        name: "Reclamos",
        component: ()=> import("../views/Reclamos/ReclamosView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR] }

      },

      {
        path: "NuevoServicio",
        name: "NuevoServicio",
        component: ()=> import("../views/Servicios/NuevoServicioView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "VerOrdenServicio/:ID(\\d+)?",
        name: "VerOrdenServicio",
        component: ()=> import("../views/Servicios/VerOrdenServicio.vue"),  
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }
 
      },

      {
        path: "GraficaFallas/:fechaIni/:fechaFin",
        name: "GraficaFallas",
        component: ()=> import("../views/Informes/graficaFallasView.vue"),  
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] },
        props: true
      },

      {
        path: "GraficaReparacionesTipo/:fechaIni/:fechaFin",
        name: "GraficaReparacionesTipo",
        component: ()=> import("../views/Informes/graficaReparacionesTipoView.vue"),   
        props: true,
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "GraficaReparaciones/:fechaIni/:fechaFin",
        name: "GraficaReparaciones",
        component: ()=> import("../views/Informes/graficaEstadisticaReparacionesView.vue"),   
        props: true,
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "GraficaServicios/:fechaIni/:fechaFin",
        name: "GraficaServicios",
        component: ()=> import("../views/Informes/graficaEstadisticaServiciosView.vue"),   
        props: true,
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "GraficaEncuestas/:fechaIni/:fechaFin/:tipo",
        name: "GraficaEncuestas",
        component: ()=> import("../views/Informes/graficaEncuestasView.vue"),   
        props: true,
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "Repuestos",
        name: "ListaRepuestos",
        component: ()=> import("../views/Repuestos/ListadoRepuestosView.vue"),
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      {
        path: "Repuestos/Crear",
        name: "CrearRepuesto",
        component: ()=> import("../views/Repuestos/InformacionRepuesto.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      {
        path: "Repuestos/:id(\\d+)?/editar",
        name: "EditarRepuesto",
        component: ()=> import("../views/Repuestos/InformacionRepuesto.vue"), 
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },
      {
        path: "VerOrdenReparacion/:ID(\\d+)?",
        name: "VerOrdenReparacion",
        component: ()=> import("../views/Reparaciones/VerOrdenReparacion.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] }

      },

      {
        path: "ubicacion-de-equipos",
        name: "ArbolEquipos",
        component: ()=> import("../views/Equipos/EquiposTree.vue"),
        meta: { roles: [RolesEnum.ADMINISTRADOR] }

      },

      { path: "reclamos/:ID(\\d+)?/reclamo-a-servicio", name: 'ReclamoAServicio', component : ()=> import("../views/ReclamoAServicio/ReclamoAServicioView.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},


      {
        path: "Configuracion",
        name: "Configuracion",
        meta: { roles: [RolesEnum.ADMINISTRADOR] },
        component: ()=> import("../views/Configuracion/ConfiguracionPage.vue"), 
      },
      { path : 'Configuracion/Cuenta',   name: 'Cuenta', component : ()=> import("../views/Configuracion/Cuenta/CuentaView.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Sucursales',   name: 'CuentaSucursales', component : ()=> import("../views/Configuracion/Sucursales/CuentaSucursalesView.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/LogosEImpresiones',   name: 'LogosEImpresiones', component : ()=> import("../views/Configuracion/LogoEImpresion/LogoEImpresionPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Departamentos',      name: 'Departamentos', component : ()=> import("../views/Configuracion/Departamentos/CuentaDepartamentosPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Correos',      name: 'Correos', component : ()=> import("../views/Configuracion/DiseñoDeCorreos/DiseñoDeCorreosPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Usuarios',      name: 'Usuarios', component : ()=> import("../views/Configuracion/Usuarios/CuentaUsuariosPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Grupos',      name: 'Grupos', component : ()=> import("../views/Configuracion/Grupos/CuentaGruposPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Categorias', name: 'Categorias', component : ()=> import("../views/Configuracion/Categorias/CuentaCategoriasPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Impuestos', name: 'Impuestos', component : ()=> import("../views/Configuracion/Impuestos/CuentaImpuestosPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Cotizaciones', name: 'Cotizaciones', component : ()=> import("../views/Configuracion/Cotizaciones/CuentaCotizacionesPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/ConceptosDeManoDeObra', name: 'ConceptosDeManoObra', component : ()=> import("../views/Configuracion/ConceptosManoObra/ConceptosManoObraPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Repuestos', name: 'Repuestos', component : ()=> import("../views/Configuracion/Repuestos/CuentaRepuestosPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/App', name: 'App', component : ()=> import("../views/Configuracion/App/CuentaAppPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Servicios', name: 'CuentaServicios', component : ()=> import("../views/Configuracion/Servicios/CuentaServiciosPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Reparaciones', name: 'CuentaReparaciones', component : ()=> import("../views/Configuracion/Reparaciones/CuentaReparacionesPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/EstadosServicios', name: 'CuentaEstadosServicios', component : ()=> import("../views/Configuracion/EstadoServicios/CuentaEstadoServicios.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/EstadosReparaciones', name: 'CuentaEstadosReparaciones', component : ()=> import("../views/Configuracion/EstadoReparaciones/CuentaEstadoReparaciones.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},  
      { path : 'Configuracion/Mantenimientos', name: 'CuentaMantenimientos', component : ()=> import("../views/Configuracion/Mantenimiento/CuentaMantenimientoPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Presupuesto', name: 'CuentaPresupuesto', component : ()=> import("../views/Configuracion/Presupuesto/CuentaPresupuestoPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/NotificacionesEmail', name: 'CuentaNotificacionesEmail', component : ()=> import("../views/Configuracion/NotificacionesEmail/CuentaNotificacionesEmailPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/EncuestasDeSatisfaccion', name: 'CuentaEncuestasSatisfaccion', component : ()=> import("../views/Configuracion/EncuestaSatisfaccion/EncuestaSatisfaccionPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Fallas', name: 'Fallas', component : ()=> import("../views/Configuracion/Fallas/FallasPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},
      { path : 'Configuracion/Rubros', name: 'Rubros', component : ()=> import("../views/Configuracion/Rubros/RubroPage.vue"),meta: { roles: [RolesEnum.ADMINISTRADOR] },},

      {
        path: "TableroMando",
        name: "TableroMando",
        component: ()=> import("../views/TableroMando/TableroMandoView.vue"),   
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] },
      },

      {
        path: "MapaTecnicos",
        name: "MapaTecnicos",
        meta: { roles: [RolesEnum.ADMINISTRADOR] },
        component: ()=> import("../views/MapaTecnicos/MapaTecnicosView.vue"),   
      },
      {
        path: "Proveedores",
        name: "Proveedores",
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] },
        component: ()=> import("../views/Proveedores/ListadoProveedoresView.vue"),   
      },
      {
        path: "Proveedor/Crear",
        name: "CrearProveedor",
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] },
        component: ()=> import("../views/Proveedores/InformacionProveedor.vue"),   
      },
      {
        path: "Proveedor/:id/editar",
        name: "EditarProveedor",
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] },
        component: ()=> import("../views/Proveedores/InformacionProveedor.vue"), 
      },

      {
        path: "PlanActual",
        name: "PlanActual",
        meta: { roles: [RolesEnum.ADMINISTRADOR] },
        component: ()=> import("../views/SubirPlan/DetallesPlanActual.vue"), 
      },

      {
        path: "Calendario",
        name: "Calendario",
        meta: { roles: [RolesEnum.ADMINISTRADOR,RolesEnum.USUARIO] },
        component: ()=> import("../views/Calendario/VerCalendario.vue"), 
      },
      {
        path: "mttr",
        name: "MTTR",
        meta: { roles: [RolesEnum.ADMINISTRADOR]},
        component:()=> import("../views/Estadisticas/MTTR.vue")
      }

    ]
  },
  { path: "/:pathMatch(.*)*", name: "notFound" ,component: () => import("../views/PageNotFound/PageNotFound.vue")}



 
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

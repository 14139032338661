
import { postRequest } from "@/helpers/fetch-wrapper";

const url =  process.env.VUE_APP_URL_SERVIDOR_PHP + "/Cuenta_controller/";

export default {
    update(cuentaData){
        const body  =  {  
            ...cuentaData
        } 
        return postRequest(url + "update", body);
    },

    updateImpresionData(impresionData){
        const body  =  { 
            ...impresionData 
        } 
        return postRequest(url + "updateImpresionesData", body);
    },



    getServicioEnable() {
       
        return postRequest(url + "servicio_enable", {});
    },

    getWhatsappEnable() {
    
        return postRequest(url + "whatsapp_enable", {});
    },

    getSuscripcionWpp() {
       
        return postRequest(url + "getSuscripcionWpp", {}); 
    },

    updateWhatsappData(data){
        const body  = { 
            ...data,
        } 
        return postRequest(url + "updateWhatsappData", body);

    },

    getCuentaData(){
      
        return postRequest(url + "getCuentaData", {});

    },

    desactivarWpp(){
        return postRequest(url + "desactivarWpp", {});
    },

    existByEmail(email){
        const body = {
            email
        }
        return postRequest(url + "existByEmail", body);

    },

    calcularDiferenciaPlan(plan){
        const body  = {
            plan : plan
        } 
        return postRequest(url + "calcularDiferenciaPlan", body);
    },

    getPaisCuenta(){
       
        return postRequest(url + "getPaisCuenta", {});
      },
  
    updateCorreoDesingData(data){
        const body  = {
            ...data
        }
        return postRequest(url + "updateCorreoDesingData", body);


    },

    updateAppData(data){
        const body  = {
            ...data
        }
        return postRequest(url + "updateAppData", body);
    },

    updateServiciosData(data){
        const body  = {
            ...data
        }
        return postRequest(url + "updateServiciosData", body);
    },

    updateMantenimientosData(data){
        const body  = {
            ...data
        }
        return postRequest(url + "updateMantenimientosData", body);
    },

    updateReparacionesData(data){
        const body  = { 
            ...data
        }
        return postRequest(url + "updateReparacionesData", body);
    },

    updateRepuestosData(data){
        const body  = {
            ...data
        }
        return postRequest(url + "updateRepuestosData", body);
    },

    updateEmailData(data){
        const body  = {
            ...data
        }
        return postRequest(url + "updateEmailData", body);

    },

    getConsumoCuenta(){
       
        return postRequest(url + "getConsumoCuenta", {});

    },

    getDatosGenerales(){
        return postRequest(url + "getDatosGenerales", {});
    },

    getDatosLogoEImpresiones(){
        return postRequest(url + "getDatosLogoEImpresiones", {});
    },

    getCorreoData(){
        return postRequest(url + "getCorreoData", {});
    },

    getMantenimientoData(){
        return postRequest(url + "getMantenimientoData", {});
    },

    getRepuestoData(){
        return postRequest(url + "getRepuestoData", {});
    },

    getNotificacionesEmailData(){
        return postRequest(url + "getNotificacionesEmailData", {});
    },

    getAppData(){
        return postRequest(url + "getAppData", {});
    },

    getServiciosData(){
        return postRequest(url + "getServiciosData", {});
    },

    getReparacionesData(){
        return postRequest(url + "getReparacionesData", {});
    },

    getPresupuestoData(){
        return postRequest(url + "getPresupuestoData", {});
    },

    updatePresupuestoData(data){
        return postRequest(url + "updatePresupuestoData", data);
    },

    moduloHabilitaciones(){
        return postRequest(url + "moduloHabilitaciones", {});
    }
};      
import Cookies from 'quasar/src/plugins/Cookies.js';;
import { postRequest } from "@/helpers/fetch-wrapper";

const url =  process.env.VUE_APP_URL_SERVIDOR_PHP + "/Usuarios_controller/";

export default {

    getMyInfo(){
        const body = { token: Cookies.get("user").message};
        return postRequest(url+"getMyInfo", body);
    },

    getUsuarios(page,rowsPerPage , filters) {
        return postRequest(url + "getUsuarios", { page, rowsPerPage, filters});
    },

    create(data){
        const body = { token: Cookies.get("user").message , ...data};
        return postRequest(url+"create",body);  
    },

    edit(userId, data){
        const body = { token: Cookies.get("user").message , ...data, userId};
        return postRequest(url+"edit",body);  

    },
    delete(userId){
        const body = { token: Cookies.get("user").message ,  userId};
        return postRequest(url+"delete",body);  
    },

    existByEmail(email){
        const body = {  email};
        return postRequest(url+"existByEmail",body);  

    },

    reestablecerPassword(data){
        const body = { token: Cookies.get("user").message , ...data};
        return postRequest(url+"restablecerPassword",body);  
    },
    
    reestablecerPasswordUser(userId, newPassword){
        const body = { token: Cookies.get("user").message , userId, newPassword};
        return postRequest(url+"restablecerPasswordUser",body);  
    },

    activar(userId){
        const body = { token: Cookies.get("user").message ,  userId};
        return postRequest(url+"activar",body);  
    },
};
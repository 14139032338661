import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Cookies from 'quasar/src/plugins/Cookies.js';import Quasar from 'quasar/src/vue-plugin.js';;

import quasarUserOptions from "./quasar-user-options";

import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

import $userPreferencesStore from "./store/userPreferencesStore";

createApp(App)
  .use(Quasar, quasarUserOptions, Cookies)
  .use(router)
  .use(VueTelInput)
  .use($userPreferencesStore)
  .mount("#app");


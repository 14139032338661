
import index_controller from "@/service/index_controller";
import Cookies from 'quasar/src/plugins/Cookies.js';;
import { RolesEnum } from "@/helpers/enums/RolesEnum";

export const  checkUserSessionGuard =  async(to,from,next) =>{
    if (!Cookies.has("user")) {
        next({name: 'login'})
        return false;
    }
    try{
        const response = await index_controller.validarToken();
        if(response.data.status == "error"){
            next({name: 'login'});
            return false;
        }
        const { user } = response.data;
        if(to.meta.roles.includes(RolesEnum.USUARIO)){
            next();
            return true;
        }
        if(to.meta.roles.includes(RolesEnum.ADMINISTRADOR) && user.esAdministrador){
            next();
            return true;
        }
        next({name:'notFound'});
        return true;
    }catch(e){
        next({name:'login'})
        return false;
    }
}
import Cuenta_controller from '@/service/Cuenta_controller';
import Usuario_controller from '@/service/Usuario_controller';
import { createStore } from 'vuex'

import createPersistedState from 'vuex-persistedstate';


const $userPreferencesStore = createStore({
  state () {
    return {
      cuentaInfo: null,
      userInfo: null,
      graficosEstadisticosDisp: false,
      wppSuscrito:   false, //checkea si el usuario tiene una suscripcion al modulo de wpp,
      wppSuscritoActivo: false, //checkea si el usuario tiene una suscripcion activa al modulo de wpp,
      wppOpcion : null,
      wppInstanceId: null,
      wppApiKey: null,
      wppActivo: null, //determina si el campo la cuenta tiene el modulo de wpp activo,
      wppLogged: false  //determina si el usuario esta logueado en la instancia de wpp
    }
  },
  getters: {
    getCuentaInfo : function(state){
      return state.cuentaInfo;
    },

    getUserInfo: function(state){
      return state.userInfo;
    },

    getWppGraficosEstadisticosDisp: function(state){
      return state.graficosEstadisticosDisp;
    },

  },
  mutations: {

    setCuentaInfo(state, cuentaInfo){
      state.cuentaInfo = { ...state.cuentaInfo, ...cuentaInfo}
    },

    setUserInfo(state, userInfo){
      state.userInfo = { ...state.userInfo, ...userInfo};
    },

    setGraficosEstadisticosDisp(state, graficosEstadisticosDisp){
        state.graficosEstadisticosDisp = graficosEstadisticosDisp;
    }
  },

  actions: {
     async getCuentaData(context){
      const responseCuentaData = await Cuenta_controller.getCuentaData()
      if(responseCuentaData.data.status == 'ok'){
            const cuentaData = responseCuentaData.data.result;
            const { TiposCuentasHistoriasDeEquipos, ...cuenta} = cuentaData;
            context.commit('setCuentaInfo',cuenta);
            context.commit("setGraficosEstadisticosDisp", TiposCuentasHistoriasDeEquipos);
       }
     },

     async getUserData(context){
      const response = await Usuario_controller.getMyInfo();
      if(response.data.status == 'ok'){
            const { data } =  response.data;
            context.commit('setUserInfo',data);
       }
     }
  },

  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: 'my-vuex-store', 
    }),
  ],
})

export default $userPreferencesStore;
import "./styles/quasar.sass";
import "@quasar/extras/material-icons/material-icons.css";
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'

import Notify from 'quasar/src/plugins/Notify.js';;
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';import Loading from 'quasar/src/plugins/Loading.js';;

import langEs from 'quasar/lang/es'


// To be used on app.use(Quasar, { ... })
export default {
  notify: { /* look at QuasarConfOptions from the API card */ },
  plugins: {
    Notify,
    LocalStorage,
    Loading
  },
  lang: langEs
  
};